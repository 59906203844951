<template>
  <div class="home">
    <table-order
      :users_data="ORDERLIST"
      :title="TITLE"
      :headers="ORDERFIELDS"
      :toEditItem="newRecord"
      :toEditHeaders="toEditHeaders"
      :record="newRecord"
      :load="load"
      @update="onUpdate"
      @deleteRecord="_deleteRecord"
      @createRecord="_createBookItem"
      @editRecord="_editBookItem"
      @changeEditItem="changeEditItem"
      @getItemForEdit="getItemForEdit"
      @add="onAdd()"
      @onPagination="
        getFilterData(objectProfile, objectVehicle, sortOptions, ...arguments)
      "
      @sortBy="getFilterData(objectProfile, objectVehicle, ...arguments)"
      :page="page"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tableOrder from "@/components/TableOrder.vue";

export default {
  name: "inProgress",
  components: {
    tableOrder
  },
  data: () => {
    return {
      editedItem: null,
      toEditHeaders: [],
      page: 1,
      load: true,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: undefined,
      inProgress: true
    };
  },
  computed: {
    ...mapGetters([
      "ORDERLIST",
      "TITLE",
      "ORDERFIELDS",
      "ADDINGFIELDS",
      "User"
    ]),
    newRecord() {
      return {
        creator: {
          email: this.User.email,
          id: this.User.id
        },
        state: true
      };
    }
  },
  methods: {
    ...mapActions([
      "getOrderList",
      "deleteBookItem",
      "createBookItem",
      "editBookItem",
      "getItemByID",
      "getFields"
    ]),
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = { ordering_field: "id", desc: true },
      page = this.page,
      inProgress = this.inProgress
    ) {
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;
      this.inProgress = inProgress;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.inProgress !== undefined
        ? (arg = arg + `&finish_fact_dt__isnull=${inProgress}`)
        : "";
      this.getOrderList({ args: arg });
    },
    getOrder(page) {
      this.page = page;
      this.getOrderList({ args: `?finish_fact_dt__isnull=true&page=${page}` });
    },
    onUpdate() {
      this.load = true;
      setTimeout(() => {
        this.page = 1;
        this.getFilterData();
        this.load = false;
      }, 1000);
    },
    onAdd() {
      this.getFields({ book: "Order" }).finally(() => {
        this.toEditHeaders = this.ADDINGFIELDS;
      });
    },
    getItemForEdit(id) {
      this.getItemByID({ book: "order", id: id }).then(item => {
        this.editedItem = item.item;
        this.toEditHeaders = item.headers;
      });
    },
    changeEditItem(newVal) {
      this.editedItem = newVal;
    },
    _deleteRecord(id) {
      this.deleteBookItem({ book: "orderDelete", id: id.id }).finally(() => {
        this.getFilterData();
      });
    },
    _createBookItem(data) {
      this.load = true;
      this.createBookItem({ book: "orderCreate", data: data }).finally(() => {
        this.getFilterData();
        this.load = false;
      });
    },
    _editBookItem(data) {
      this.editBookItem({
        book: "orderEdit",
        id: `${data.id}/`,
        data: data
      }).finally(() => {
        this.getOrderList({ args: "?state=true" });
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.load = false;
      this.getFilterData();
    }, 1000);
  }
};
</script>
