<template>
  <div>
    <v-data-table
      :headers="visibleHeaders"
      :items="filteredData"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-3 table table__custom"
      :options.sync="options"
      :custom-sort="customSort"
      :loading="load"
      sort-by="id"
      :class="{ isRed: filteredData === 'state' }"
    >
      <template v-slot:[`item.state`]="{ item }">
        <v-chip color="green" class="chip" dark v-if="item.state == true">
          {{ $t("settings.active") }}
        </v-chip>
        <v-chip color="red" class="chip" dark v-if="item.state == false">
          {{ $t("settings.inActive") }}
        </v-chip>
      </template>

      <template v-slot:[`header.actions`]>
        <p class="search_category" @click.stop="show = !show">
          <v-icon color="#cbd3e9" small>search</v-icon>
          <span>{{ $t("searchCat") }}</span>
        </p>
      </template>

      <template v-slot:[`body.prepend`] v-if="headers">
        <tr v-if="show">
          <td v-for="item in visibleHeaders" :key="`search_${item.value}`">
            <v-text-field
              class="table-search"
              v-if="item.value !== 'actions'"
              height="30"
              append-icon="mdi-magnify"
              hide-details
              background-color="#fff"
              rounded
              single-line
              @input="search($event, item.value)"
            ></v-text-field>
            <v-icon v-else small @click="show = false">close</v-icon>
          </td>
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat height="120">
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <h2 class="main-title">{{ title }}</h2>
                <total :total="COUNT" />
              </v-col>
              <v-col cols="6">
                <div class="btn__row">
                  <v-btn
                    outlined
                    color="primary"
                    dark
                    @click="$emit('update')"
                    class="mr-2"
                  >
                    <v-icon>loop</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    dark
                    @click="onAdd()"
                    v-if="verificationRole()"
                  >
                    <v-icon>add</v-icon>
                    {{ $t("buttons.add") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn tag="router-link" :to="`/order/${item.id}`" icon center>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-menu offset-x left nudge-top="50%" nudge-left="10px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="verificationRole()"
              @click="deleteItem(item)"
              icon
            >
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </template>
          <deliteItem
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
          />
        </v-menu>
      </template>

      <template v-slot:no-data>
        <p class="nodata">{{ $t("title.nodata") }}</p>
      </template>
    </v-data-table>

    <div class="pagination-block" v-if="pageCounter > 1">
      <v-pagination
        v-model="_page"
        :length="pageCounter"
        :total-visible="7"
        circle
      />
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <edit-item
        @close="close"
        @edit="createRecord"
        :headers="toEditHeaders"
        :record="editedItem"
        :title="$t('new.addingAnOrder')"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import total from "@/components/Total.vue";
import editItem from "@/components/EditItem.vue";
import deliteItem from "@/components/DeliteItem.vue";

export default {
  data: () => ({
    itemsPerPage: 10,
    searchFor: {},
    searchValue: "",
    key: "",
    ifSerching: false,
    show: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    isEdit: false,
    delName: null,
    options: {}
  }),
  components: { total, editItem, deliteItem },
  props: {
    toEditHeaders: {
      type: Array,
      default: () => []
    },
    toEditItem: {
      type: Object,
      default: null
    },
    users_data: {
      type: Array
    },
    title: {
      type: String
    },
    directory: {
      type: String,
      default: ""
    },
    headers: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    load: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _page: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit("onPagination", page);
      }
    },
    pageCounter() {
      return Math.ceil(this.COUNT / 10);
    },
    editedItem: {
      get() {
        return this.toEditItem;
      },
      set(newVal) {
        this.$emit("changeEditItem", newVal);
      }
    },
    filteredData() {
      let datas = this.users_data.map(function(item) {
        let newItem = { ...item };
        const keyValues = Object.keys(newItem).map(key => {
          if (newItem[key] !== null && typeof newItem[key] === "object") {
            newItem[key] = item[key].caption;
          }
          // here the date converts to string
          if (newItem[key] !== null && key === "connected_dt") {
            let date = new Date(item[key] * 1000);
            newItem[key] = date.toString().substring(0, 21);
          }
          // if (
          //   newItem[key] !== null &&
          //   key === "state" &&
          //   newItem[key] === false
          // ) {
          //   let active = "inActive";
          //   newItem[key] = active;
          // }
          // if (
          //   newItem[key] !== null &&
          //   key === "state" &&
          //   newItem[key] === true
          // ) {
          //   let inActive = "Active";
          //   newItem[key] = inActive;
          // }
          return newItem;
        });
        return Object.assign({}, ...keyValues);
      });
      if (!this.ifSerching) {
        return datas;
      } else {
        return datas.filter(item => {
          if (item[this.key]) {
            return (
              item[this.key]
                .toString()
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
    },

    visibleHeaders() {
      return this.headers.filter(header => header.visible);
    },
    ...mapGetters(["ROLES", "ISUSER", "ISADMINCOMPANY", "ISADMIN", "COUNT"])
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.sortBy();
      },
      deep: true
    }
  },

  methods: {
    getColor(finished) {
      if (finished === "Active") {
        return "green";
      } else return "red";
    },
    customSort(items) {
      return items;
    },
    sortBy() {
      const { sortBy, sortDesc } = this.options;
      this.$emit("sortBy", { ordering_field: sortBy, desc: sortDesc });
    },
    onAdd() {
      this.dialog = true;
      this.$emit("add");
    },
    createRecord(newRecord) {
      this.$emit("createRecord", newRecord);
      this.close();
    },
    editRecord(record) {
      this.$emit("editRecord", record);
      this.close();
    },
    search(val, key) {
      this.searchValue = val;
      this.key = key;
      this.ifSerching = true;
    },
    editItem(item) {
      if (this.verificationRole()) {
        this.$emit("getItemForEdit", item.id);
        this.isEdit = true;
        this.dialog = true;
      }
    },

    deleteItem(item) {
      this.editedIndex = this.users_data.indexOf(item);
      this.delName = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let payload = {
        id: this.delName.id
      };
      this.$emit("deleteRecord", payload);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.editedItem = null;
      this.isEdit = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = null;
    },
    verificationRole() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && this.ROLES.seller) ||
        (this.ISUSER && this.ROLES.seller) ||
        (this.ISADMINCOMPANY && this.directory.match("company"))
      ) {
        return true;
      } else return false;
    }
  }
};
</script>
<style lang="scss">
.v-text-field {
  margin: 0;
  padding: 0;
}
.btn__row {
  display: flex;
  justify-content: flex-end;
  &:last-child {
    margin-top: 10px;
  }
}
.pagination-block {
  margin-top: 40px;
}
.table-search {
  max-width: 200px;
}
.nodata {
  margin: 0;
}
.main-title {
  text-transform: capitalize;
}
</style>
